exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliates-js": () => import("./../../../src/pages/affiliates.js" /* webpackChunkName: "component---src-pages-affiliates-js" */),
  "component---src-pages-black-friday-js": () => import("./../../../src/pages/black-friday.js" /* webpackChunkName: "component---src-pages-black-friday-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landify-ui-js": () => import("./../../../src/pages/landify-ui.js" /* webpackChunkName: "component---src-pages-landify-ui-js" */),
  "component---src-pages-license-js": () => import("./../../../src/pages/license.js" /* webpackChunkName: "component---src-pages-license-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-submit-design-js": () => import("./../../../src/pages/submit-design.js" /* webpackChunkName: "component---src-pages-submit-design-js" */),
  "component---src-pages-templates-js": () => import("./../../../src/pages/templates.js" /* webpackChunkName: "component---src-pages-templates-js" */),
  "component---src-templates-template-js": () => import("./../../../src/templates/template.js" /* webpackChunkName: "component---src-templates-template-js" */)
}

